<template>
  <div class="invoice-body" id="invoice-body">
    <div v-show="show" id="invoice"  style="height: 100%;">
      <div :style="{'padding': '10px','background-color': '#ffffff', 'direction': (lang == 'ar' ? 'rtl': 'ltr'), 'text-align': (lang == 'ar' ? 'right' : 'left') }">
        <div v-if="rel_logo" style="width: 100%; text-align: center;padding-bottom: 10px;padding-top: 15px;"><img :src="rel_logo" alt="" style="width: 80px;"></div>

        <h4 class="text-center" style="margin: 10px;" v-if="company && company.business_name">{{company? company.business_name : ''}}</h4>
        <h4 class="text-center" style="margin: 10px;" v-if="company && company.business_name_en">{{company? company.business_name_en : ''}}</h4>

        <span class="border-dot"></span>
        <h4 class="text-center">{{fileName}}</h4>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('pos_session.opened_date')}}: </p>
          <p style="margin: 0 0 5px 0;">{{ session.opened_date }}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('pos_session.closed_date')}}: </p>
          <p style="margin: 0 0 5px 0;"> {{ session.closed_date }}</p>
        </div>
        <div class="d-flex justify-content-between mt-2">
          <p style="margin: 0 0 5px 0;">{{$t('report')}} #: </p>
          <p style="margin: 0 0 5px 0;"> {{session.id}} </p>
        </div>
        <div class="d-flex justify-content-between mt-2">
          <p style="margin: 0 0 5px 0;">{{$t('from_documentation')}}: </p>
          <p style="margin: 0 0 5px 0;"> {{session.invoice_code_from}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('to_documentation')}}: </p>
          <p style="margin: 0 0 5px 0;"> {{session.invoice_code_to}}</p>
        </div>
        <span class="border-dot"></span>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;"><b>{{$t('user_sales')}}:</b> </p>
          <p style="margin: 0 0 5px 0;"> {{session.invoice_counts ? session.invoice_counts : 0}}</p>
        </div>
        <span class="border-dot"></span>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{session.user_name}}</p>
        </div>
        <span class="border-dot"></span>
        <div class="d-flex justify-content-between" v-if="payment_info.payments && payment_info.payments.cash">
          <p style="margin: 0 0 5px 0;">{{$t('cash')}}: </p>
          <p style="margin: 0 0 5px 0;"> {{payment_info.payments.cash.amount}} {{currency_name}}</p>
        </div>
        <div class="d-flex justify-content-between" v-if="payment_info.payments && payment_info.payments.card">
          <p style="margin: 0 0 5px 0;">{{$t('credit_card')}}: </p>
          <p style="margin: 0 0 5px 0;"> {{payment_info.payments.card.amount}}  {{currency_name}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;"></p>
          <p style="margin: 0 0 5px 0;"> <b>{{payment_info.summary ? payment_info.summary.total : '0.00'}} {{currency_name}}</b></p>
        </div>
        <span class="border-dot"></span>


        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('total_paid')}}: </p>
          <p style="margin: 0 0 5px 0;">{{payment_info.summary ? payment_info.summary.total : '0.00'}} {{currency_name}}</p>
        </div>
        <span class="border-dot"></span>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('total_refund_amount')}}: </p>
          <p style="margin: 0 0 5px 0;"> {{session.total_refund ? session.total_refund : '0.00'}} {{currency_name}}</p>
        </div>
        <span class="border-dot"></span>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;"><b>{{$t('discount_granted')}}:</b> </p>
          <p style="margin: 0 0 5px 0;">{{session.total_discount ? session.total_discount : '0.00'}} {{currency_name}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;"><b>{{$t('tax_collected')}}:</b> </p>
        </div>
        <div class="d-flex justify-content-between" v-for="(tax, index) in taxes" :key="'tax'+index">
          <p style="margin: 0 0 5px 0;">{{$t('VAT')}} {{tax.percentage}}% ({{tax.percentage}}%): </p>
          <p style="margin: 0 0 5px 0;"> {{tax.total_tax_value}} {{currency_name}}</p>
        </div>
        <span class="border-dot"></span>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('total_taxable')}}:</p>
          <p style="margin: 0 0 5px 0;">{{session.invoice_total_tax ? session.invoice_total_tax : '0.00'}} {{currency_name}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('tax_label')}}:</p>
          <p style="margin: 0 0 5px 0;"> {{session.total_tax_value_sum ? session.total_tax_value_sum : '0.00'}} {{currency_name}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p style="margin: 0 0 5px 0;">{{$t('total_total')}}:</p>
          <p style="margin: 0 0 5px 0;"> {{session.total ? session.total : '0.00'}} {{currency_name}}</p>
        </div>




      </div>

    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import jsPdfExport from "@/core/config/jsPdfExport";
import html2pdf from "html2pdf.js";


export default {
  name: "point-sale-invoice",

  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: "pos/pos-sessions",
      id: this.$route.params.id ? this.$route.params.id : null,
      type: this.$route.params.action ? this.$route.params.action : null,
      data: {},
      show: false,
      print_size: '80',
      currency_name: null,
      payment_info: [],
      session: [],
      company: [],
      taxes: [],

    };
  },
  computed: {
    rel_logo: function () {
      if (this.company && this.company.logo_url) {
        return this.company.logo_url;
      } else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
    fileName: function (){
      return this.$t('pos_session.pos_session');
      // let _invoice_label = this.$i18n.locale ==  'ar' ? 'فاتورة مبيعات' : 'Sales invoice';
      // let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      // let _invoice_code = (this.data.invoice_code) ? this.data.invoice_code : '';
      // return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  methods: {
    mobileFullData(mobile){
      if (mobile){
        if (mobile.length > 5){
          return mobile;
        }
      }
      return null;
    },
    formatNumber(number) {
      // Format the number with commas if it's greater than 5 digits
      return number > 9999 ? new Intl.NumberFormat().format(number) : number;
    },
    showPaymentDetails(payment_amount_details){
      if (payment_amount_details && payment_amount_details.length > 0){
        let status = false;
        payment_amount_details.forEach((row)=>{
          if (row.amount != 0 && row.amount != null){
            status = true;
          }
        });
        return status;
      }else {
        return false;
      }
    },
    async getData() {
      let _id = this.id;
      await ApiService.get(`${this.mainRoute}/${_id}/summary`).then((response) => {
        this.data = response.data.data;
        this.session = response.data.data.session;
        if(response.data.data.session && response.data.data.session.company){
          this.company = response.data.data.session.company
        }
        if(response.data.data.session && response.data.data.session.currency_name){
          this.currency_name = response.data.data.session.currency_name
        }
        if(response.data.data.session && response.data.data.session.payment_info){
          this.payment_info = response.data.data.session.payment_info
        }
        if(response.data.data.session && response.data.data.session.taxes){
          this.taxes = response.data.data.session.taxes.length ? response.data.data.session.taxes : []
        }
        this.show = true;
      });
    },

    // async getData() {
    //   let _id = this.idEditing;
    //   await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

    //     this.data = response.data.data;
    //     this.items_list = response.data.data.items_list;
    //     // this.show = true;
    //   });
    // },
    async printContent() {
      if (document.getElementById('invoice-body')) {
        const content = document.getElementById('invoice-body').innerHTML;

        const printWindow = window.open('', '_self');

        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>');
        printWindow.document.write(`

              @media print {
                @page {
                  size: auto;
                  margin: 2mm;
                }
              }
              .store-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 99px;
                margin: 13px;
              }
              #invoice {
                font-family: "NotoSansArabic";
                width: ${this.print_size}mm;
                position: relative;
              }
              h5{
                margin:0;
              }
              html, body {
                    margin: 0.39mm 0.27mm;
                    background: #EEF0F8;
              }

              table, thead, tr, th, tbody, td {

                font-size: 14px;
                background-color: transparent !important;
              }

              table{
                border: 1px solid #000000;
                border-radius: 6px;
                border-color: #000000 !important;
                width: 100%;
              }

              .text-center {
                text-align: center !important;
              }

              th, p, h5 {

                font-size: 14px !important;

              }

              .special-border {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
              }
              .table td {
                border-left: 1px solid #000000 !important;
                border-top: 0;
              }
              .table thead th {
                border-left: 1px solid #000000;
                border-bottom: 1px solid #000000;
              }
              .table thead tr {
                border: 0 !important;
              }
              .table thead th:last-child {
                border-left: 0 !important;
              }
              .table td:last-child {
                border-left: 0 !important;
              }
              .table th, .table td {
                padding: 5px !important;
              }
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
.border-dot{
  border-bottom: 2.5px dotted #000;
  height: 5px;
  width: 100%;
  display: block;
  margin: 10px 0 15px 0;
}
.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}
p{
    margin: 0 0 5px 0;
}
.text-center{
    text-align: center;
}
.mt-5{
    margin-top: 1.25rem !important;
}
.mt-2{
    margin-top: 0.5rem !important;
}
          `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          printWindow.print();
          // printWindow.close();
          // this.completedForPrint();
        }, 500);
      }
    },
    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          window.close();
        }
      }

    },
    printData() {
      let _id = "invoice";
      if (this.type == "pdf") {
        this.exportPDF(_id);
      } else {
        this.printContent();
      }
    },
    exportPDF(_id) {
      let that = this;
      let element = document.getElementById(_id);
      let width = element.clientWidth;
      let height = element.clientHeight;

      // let pdf = jsPdfExport80MM("p", "px", [width, height]);
      let pdf = jsPdfExport("p", "px", [width - 90, height]);

      pdf.html(document.getElementById(_id), {
        html2canvas: {
          width: width,
          height: height,
          scale: 0.69
        },
        callback: function (pdf) {
          pdf.save(that.fileName);
        },
      });
    },

  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printData();
    });
  },
};
</script>

<style>
@media print {
  @page {
    size: auto;
    margin: 2mm;
  }
}
.border-dot{
  border-bottom: 2.5px dotted #000;
  height: 5px;
  width: 100%;
  display: block;
}
.store-logo {
  position: absolute;
  top: 0;
  right: 0;
  height: 99px;
  margin: 13px;
}

#invoice {
  font-family: "Amiri";
  width: 76mm;
  position: relative;
}

h5 {
  margin: 0;
}

html, body {
  margin: 0.39mm 0.27mm;
  background: #EEF0F8;
}

table, thead, tr, th, tbody, td {

  font-size: 14px;
  background-color: transparent !important;
}

table {
  border: 1px solid #000000;
  border-radius: 6px;
  border-color: #000000 !important;
  width: 100%;
}

.text-center {
  text-align: center !important;
}

th, p, h5 {

  font-size: 14px !important;

}

.special-border {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.table td {
  border-left: 1px solid #000000 !important;
  border-top: 0;
}

.table thead th {
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.table thead tr {
  border: 0 !important;
}

.table thead th:last-child {
  border-left: 0 !important;
}

.table td:last-child {
  border-left: 0 !important;
}

.table th, .table td {
  padding: 5px !important;
}
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
</style>